import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CompanyFormWidget from '../../../widgets/companies/CompanyFormWidget';
import CompanyToolbarWidget from '../../../widgets/companies/CompanyToolbarWidget';
import useAuth from '../../../shared/lib/hooks/useAuth';
import { getAppUserAccesses } from '../../../shared/api/api';
import UserFormWidget from '../../../widgets/users/UserFormWidget';
import VehicleFormWidget from '../../../widgets/vehicles/VehicleFormWidget/VehicleFormWidget';
import { NODES } from '../../../entities/tree/config';
import DivisionFormWidget from '../../../widgets/divisions/DivisionFormWidget/DivisionFormWidget';
import NoData from '../../../shared/ui/NoData/NoData';

// Отображает вкладку "Общая информация" с полями редакитрования и кнопками
function CompanyInfoPanel(props) {
  const {
    selectedData, remove, add, updateTree, updateTreeForCompany,
    setIsFormChanged, isFormChanged, editedNodeData, isErr, setIsErr
  } = props;
  // Текущий авторизовавшийся пользователь
  const { user } = useAuth();

  // Роли текущего пользователя
  const [isAdmin] = useState(user.authorities.includes('ROLE_ADMIN'));
  const [isCompanyAdmin] = useState(user.authorities.includes('ROLE_COMPANY_ADMIN'));
  const [isUser] = useState(user.authorities.includes('ROLE_USER'));
  const [isVehicleAdmin, setIsVehicleAdmin] = useState(false);
  const [isUserAdmin, setIsUserAdmin] = useState(false);

  // Список привилегий для текущего пользователя с ролью 'ROLE_USER'
  const [appUserAccesses, setAppUserAccesses] = useState([]);

  // Получение привилегий с сервера
  useEffect(() => {
    if (isUser && !isAdmin && !isCompanyAdmin) {
      getAppUserAccesses({ userId: user.id, }).then((res) => {
        setAppUserAccesses(res);
      });
    }
  }, [isAdmin, isCompanyAdmin, isUser, user]);

  // Обновление роли для привилегии 'DIV_PRIV_USER_ADMIN'
  useEffect(() => {
    if (appUserAccesses.length) {
      setIsUserAdmin(Boolean(appUserAccesses.find((userAccess) => (
        userAccess.divPrivilege === 'DIV_PRIV_USER_ADMIN'
      ))));
    }
  }, [appUserAccesses]);

  // Обновление роли для привилегии 'DIV_PRIV_VEHICLE_ADMIN'
  useEffect(() => {
    if (appUserAccesses.length) {
      if (selectedData?.nodeType === NODES.VEHICLE.TYPE
        || selectedData?.nodeType === NODES.DIVISION.TYPE) {
        const userAccessVihicleAdmin = appUserAccesses
          .find((userAccess) => (
            userAccess.division.divisionId
            === (selectedData.divisionId || selectedData.division?.divisionId)
            && userAccess.divPrivilege === 'DIV_PRIV_VEHICLE_ADMIN'
          ));
        setIsVehicleAdmin(Boolean(userAccessVihicleAdmin));
      }
    }
  }, [appUserAccesses, selectedData]);

  useEffect(() => setIsErr(false), []);

  const renderForm = (nodeType) => {
    switch (nodeType) {
      case NODES.USER.TYPE:
        return (
          <UserFormWidget
            updateTree={updateTree}
            selectedUser={editedNodeData || selectedData}
            isAdmin={isAdmin}
            isCompanyAdmin={isCompanyAdmin}
            isFormChanged={isFormChanged}
            setIsFormChanged={setIsFormChanged}
            readOnly={!((isAdmin || isCompanyAdmin || isUserAdmin))}
            add={add}
            company={selectedData.company}
          />
        );
      case NODES.VEHICLE.TYPE:
        return (
          <VehicleFormWidget
            updateTree={updateTree}
            selectedVehicle={editedNodeData || selectedData}
            company={selectedData.company}
            isFormChanged={isFormChanged}
            setIsFormChanged={setIsFormChanged}
            readOnly={!((isAdmin || isCompanyAdmin || isVehicleAdmin))}
          />
        );
      case NODES.DIVISION.TYPE:
        return (
          <DivisionFormWidget
            updateTree={updateTree}
            selectedDivision={editedNodeData || selectedData}
            company={selectedData.company}
            readOnly={!((isAdmin || isCompanyAdmin))}
            setIsFormChanged={setIsFormChanged}
            isFormChanged={isFormChanged}
          />
        );
      case NODES.COMPANY.TYPE:
        return (
          <CompanyFormWidget
            updateTreeForCompany={updateTreeForCompany}
            selectedCompany={editedNodeData || selectedData}
            readOnly={!((isAdmin || isCompanyAdmin))}
            setIsFormChanged={setIsFormChanged}
            isFormChanged={isFormChanged}
            isErr={isErr}
            setIsErr={setIsErr}
          />
        );
      default:
        return (
          <NoData />
        );
    }
  };

  return (
    <Box sx={{ pt: 2 }}>
      <CompanyToolbarWidget
        selectedData={editedNodeData || selectedData}
        remove={remove}
        add={add}
        isAdmin={isAdmin}
        isCompanyAdmin={isCompanyAdmin}
        isUser={isUser}
        isVehicleAdmin={isVehicleAdmin}
        isUserAdmin={isUserAdmin}
      />
      {selectedData
        ? renderForm(editedNodeData?.nodeType || selectedData.nodeType)
        : null}
    </Box>
  );
}

CompanyInfoPanel.propTypes = {
  selectedData: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.bool,
    PropTypes.array,
  ])),
  editedNodeData: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.bool,
    PropTypes.array,
  ])),
  remove: PropTypes.func.isRequired,
  add: PropTypes.func.isRequired,
  updateTree: PropTypes.func.isRequired,
  updateTreeForCompany: PropTypes.func.isRequired,
  isFormChanged: PropTypes.bool.isRequired,
  setIsFormChanged: PropTypes.func.isRequired,
  isErr: PropTypes.bool,
  setIsErr: PropTypes.func,
};

CompanyInfoPanel.defaultProps = {
  selectedData: null,
  editedNodeData: null,
  isErr: null,
  setIsErr: null,
};

export default CompanyInfoPanel;
