import React, {
  Children, cloneElement, useCallback, useEffect, useState
} from "react";
import PropTypes from "prop-types";
import { useLazyGetVehiclesResourceQuery } from "../redux/vehicle.api";
import {
  useLazyGetVehicleWeekMileagesResourceQuery, usePatchVehicleWeekMileagesResourceMutation,
  usePostVehicleWeekMileagesResourceMutation
} from "../../vehicleWeekMileagesResource/redux/vehicleWeekMileagesResource.api";
import { useLazyGetTrackPointsResourceQuery } from "../../trackPointsResource/redux/trackPointsResource.api";
import useAuth from "../../../../shared/lib/hooks/useAuth";
// eslint-disable-next-line boundaries/element-types
import { useGetAppUserResourceIdQuery } from "../../../account/appUsersResource/redux/appUsersResource.api";

// Компонента Бизнес уровня которая является связующим звеном между фронтом и бизнесом
// Компонента возвращает Children с новыми пропсами из бизнес уровня
function VehicleReportingBll({
  children, vehicleId
}) {
  // TODO: переписать token на стор
  // eslint-disable-next-line no-undef
  const token = window.localStorage.getItem('token') || window.sessionStorage.getItem('token');

  const { user } = useAuth();

  const { data: dataAppUser } = useGetAppUserResourceIdQuery(
    {
      id: user.appUserId,
    }
  );

  const [
    fetchDataVehiclesResource,
    { data: dataVehiclesResource }
  ] = useLazyGetVehiclesResourceQuery();

  useEffect(() => {
    const id = dataAppUser?.data?.company?.companyId;
    if (id) {
      fetchDataVehiclesResource({
        id: vehicleId,
        params: {
          "companyId.equals": id
        }
      });
    }
  }, [dataAppUser?.data?.company?.companyId, token]);

  const [postVehicleWeekMileagesResource] = usePostVehicleWeekMileagesResourceMutation();

  const [getVehicleWeekMileages] = useLazyGetVehicleWeekMileagesResourceQuery();

  const [patchVehicleWeekMileagesResource] = usePatchVehicleWeekMileagesResourceMutation();

  const [
    fetchTrackPoints,
  ] = useLazyGetTrackPointsResourceQuery();

  const patchVehicleWeekMileagesResourceParams = useCallback(
    ({ body, id }) => patchVehicleWeekMileagesResource({
      body,
      id
    }),
    []
  );

  const getTrackPointsResourceParams = useCallback(
    ({ params }) => fetchTrackPoints({
      authToken: token,
      params: {
        ...params
      }
    }),
    [vehicleId]
  );

  const getVehicleWeekMileagesParams = useCallback(
    ({ params }) => getVehicleWeekMileages({
      params
    }),
    []
  );

  const postVehicleWeekMileagesResourceParams = useCallback(
    ({ body }) => postVehicleWeekMileagesResource({
      body
    }),
    []
  );

  const [data, setData] = useState(null);

  useEffect(() => {
    const newData = {};
    newData.dataVehiclesResource = dataVehiclesResource;
    setData(newData);
  }, [
    dataVehiclesResource,
  ]);

  const props = {
    data,
    vehicleId,
    postVehicleWeekMileagesResourceParams,
    getVehicleWeekMileagesParams,
    patchVehicleWeekMileagesResourceParams,
    getTrackPointsResourceParams
  };

  return (
    <>
      {Children.toArray(children).map((child) => cloneElement(child, props))}
    </>
  );
}

VehicleReportingBll.propTypes = {
  children: PropTypes.element.isRequired,
  vehicleId: PropTypes.number,
};

VehicleReportingBll.defaultProps = {
  vehicleId: NaN,
};

export default VehicleReportingBll;
