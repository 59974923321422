import React, { useEffect, useState } from 'react';
import {
  Box, Button, CircularProgress, IconButton, TextField, Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import { Route, Routes, useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MenuItem from "@mui/material/MenuItem";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import Divider from "@mui/material/Divider";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from '@mui/icons-material/Add';
import { useConfirm } from "material-ui-confirm";
import { DateTimePicker } from "@mui/x-date-pickers";
import PauseIcon from '@mui/icons-material/Pause';
import {
  useLazyDeleteLogisticTasksQuery,
  useLazyGetLogisticTasksQuery,
  usePauseLogisticTasksMutation,
  useStartLogisticTasksMutation, useStopLogisticTasksMutation
} from "../../../entities/logistic/logisticTasks/redux/logisticTasks.api";
import style from './CompanyLogisticsPanel.module.css';
import EnhancedTableHead from "../../../shared/ui/EnhancedTableHead/EnhancedTableHead";
import StyledMenu from "../../../shared/ui/StyledMenu/StyledMenu";
import isValidDate from "../../../shared/utils/isValidDate";
import CompanyLogisticsPanel from "../CompanyLogisticsPanel/CompanyLogisticsPanel";
import CompanyDeliveryOrdersPanel from "../CompanyDeliveryOrdersPanel/CompanyLogisticsPanel";
import ActionBarDateTimePicker from "../../../shared/ui/ActionBarDateTimePicker/ActionBarDateTimePicker";
import CompanyLogisticPowerBalancePanel from "../CompanyLogisticPowerBalancePanel/CompanyLogisticPowerBalancePanel";

const toRuStatus = (e) => {
  switch (e) {
    case "CREATED": return "Создан";
    default: return e;
  }
};

const toRuType = (row) => {
  const e = row?.type || row?.solution?.type;
  switch (e) {
    case "VRP":
    case "VRPSolution":
      return "Доставка заказов";
    case "SHORT_PATH":
    case "shortPathSolution":
      return "Построение оптимального маршрута";
    case "BALANCE_CAPACITY":
    case "balanceCapacity":
      return "Динамическая балансировка мощностей";

    default: return e;
  }
};

function formatDate(date) {
  // Получаем компоненты даты
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
  const year = String(date.getFullYear()).slice(-2); // Берем только последние две цифры года
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  // Формируем строку в нужном формате
  return `${day}.${month}.${year} ${hours}:${minutes}`;
}

function sortByDateDescending(array) {
  return array?.sort((a, b) => {
    const dateA = new Date(a.createdDate);
    const dateB = new Date(b.createdDate);
    return dateB - dateA;
  });
}

// Отображает панель "Логистики" для ТС
function CompanyLogisticsTablePanel({ company }) {
  const navigate = useNavigate();

  return (
    <Routes>
      <Route
        path=""
        element={(
          <CompanyLogisticsTable companyId={company.companyId} />
            )}
      />
      <Route
        path="create"
        element={(
          <>
            <CompanyLogisticsPanel
              company={company}
            />
            <CompanyDeliveryOrdersPanel />
            <CompanyLogisticPowerBalancePanel />
            <Button
              disableElevation
              onClick={() => navigate(window.location.pathname.replaceAll("/create", ""))}
              variant="contained"
              sx={{ marginLeft: "auto", display: "block" }}
            >
              Назад
            </Button>
          </>
          )}
      />

    </Routes>

  );
}

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Название',
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Статус',
  },
  {
    id: 'type',
    numeric: true,
    disablePadding: false,
    label: 'Тип',
  },
  {
    id: 'isRunnable',
    numeric: true,
    disablePadding: false,
    label: 'Запущен',
  },
  {
    id: 'startTime',
    numeric: true,
    disablePadding: false,
    label: 'Дата запуска',
  },
  {
    id: 'createdDate',
    numeric: true,
    disablePadding: false,
    label: 'Дата создания',
  },
  {
    id: 'action',
    numeric: true,
    disablePadding: false,
    label: '',
  },
];

function EnhancedTableToolbar({
  // eslint-disable-next-line react/prop-types
  setStartDateCreatedAt,
  // eslint-disable-next-line react/prop-types
  setFinishDateCreatedAt,
  // eslint-disable-next-line react/prop-types
  setStartDateStartAt,
  // eslint-disable-next-line react/prop-types
  setFinishDateStartAt,
  // eslint-disable-next-line react/prop-types
  startDateCreatedAt,
  // eslint-disable-next-line react/prop-types
  finishDateCreatedAt,
  // eslint-disable-next-line react/prop-types
  startDateStartAt,
  // eslint-disable-next-line react/prop-types
  finishDateStartAt,
  // eslint-disable-next-line react/prop-types
  name,
  // eslint-disable-next-line react/prop-types
  setName
}) {
  const navigate = useNavigate();

  const handleResetFilters = () => {
    setStartDateCreatedAt(null);
    setFinishDateCreatedAt(null);
    setStartDateStartAt(null);
    setFinishDateStartAt(null);
    setName("");
  };

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  return (
    <>
      <Box
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
      >

        <Box
          sx={{
            display: "flex", alignItems: "center", flex: "1", gap: "10px"
          }}
        >
          <Box sx={{
            width: '100%', display: 'flex', gap: '20px', justifyContent: 'right', paddingTop: '10px'
          }}
          >
            <IconButton
              title="Создать задачу"
              onClick={() => {
                navigate("create");
              }}
            >
              <AddIcon />
            </IconButton>
            <Tooltip title="Фильтры">
              <IconButton
                onClick={() => {
                  setIsFilterOpen((prev) => !prev);
                }}
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      {isFilterOpen ? (
        <Box sx={{
          width: "max-content",
          pl: "20px"
        }}
        >
          <Box
            sx={{
              display: "flex", alignItems: "center", flex: "1", gap: "10px", pt: "30px"
            }}
          >
            <Box sx={{
              width: "100%",
              display: "flex",
              gap: "20px",
            }}
            >
              <TextField
                size="small"
                id="name"
                label="Название задачи"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              <Box sx={{
                display: "flex",
                width: "401px",
                gap: "20px",
                position: "relative"
              }}
              >
                <Box sx={{
                  position: "absolute",
                  left: "50%",
                  transform: "translate(-50%, -30px)"
                }}
                >
                  Дата создания
                </Box>
                <DateTimePicker
                  label="С"
                  value={startDateCreatedAt}
                  onChange={(e) => {
                    if (isValidDate(e)) {
                      setStartDateCreatedAt(e);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      {...params}
                    />
                  )}
                  components={{
                    ActionBar: ActionBarDateTimePicker,
                  }}
                />
                <DateTimePicker
                  label="По"
                  value={finishDateCreatedAt}
                  onChange={(e) => {
                    if (isValidDate(e)) {
                      setFinishDateCreatedAt(e);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      {...params}
                    />
                  )}
                  components={{
                    ActionBar: ActionBarDateTimePicker,
                  }}
                />
              </Box>
              <Box sx={{
                display: "flex",
                width: "401px",
                gap: "20px",
                position: "relative"
              }}
              >
                <Box sx={{
                  position: "absolute",
                  left: "50%",
                  transform: "translate(-50%, -30px)"
                }}
                >
                  Дата запуска
                </Box>

                <DateTimePicker
                  label="С"
                  value={startDateStartAt}
                  onChange={(e) => {
                    if (isValidDate(e)) {
                      setStartDateStartAt(e);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      {...params}
                    />
                  )}
                  components={{
                    ActionBar: ActionBarDateTimePicker,
                  }}
                />
                <DateTimePicker
                  label="По"
                  value={finishDateStartAt}
                  onChange={(e) => {
                    if (isValidDate(e)) {
                      setFinishDateStartAt(e);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      {...params}
                    />
                  )}
                  components={{
                    ActionBar: ActionBarDateTimePicker,
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box>
            <Button
              onClick={handleResetFilters}
              variant="text"
              sx={{
                mt: "20px",
                display: "block",
                marginLeft: "auto"
              }}
            >
              Сбросить
            </Button>
          </Box>
        </Box>
      ) : null }

    </>
  );
}
// eslint-disable-next-line react/prop-types
function CompanyLogisticsTable({ companyId }) {
  const navigate = useNavigate();
  const confirm = useConfirm();

  const [getLogisticTasks] = useLazyGetLogisticTasksQuery();

  const [pauseLogisticTasks] = usePauseLogisticTasksMutation();
  const [startLogisticTasks] = useStartLogisticTasksMutation();
  const [deleteLogisticTasks] = useLazyDeleteLogisticTasksQuery();
  const [stopLogisticTasks] = useStopLogisticTasksMutation();
  const [name, setName] = useState("");
  const [startDateCreatedAt, setStartDateCreatedAt] = useState(null);
  const [finishDateCreatedAt, setFinishDateCreatedAt] = useState(null);
  const [startDateStartAt, setStartDateStartAt] = useState(null);
  const [finishDateStartAt, setFinishDateStartAt] = useState(null);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [logisticTask, setLogisticTask] = useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);

  const selectedTasksIsRunnable = logisticTask?.map((e) => {
    const itemSelected = selectedTasks.find((el) => el === e.logisticTaskId);

    if (itemSelected) {
      return !!e.isRunnable;
    }
    return true;
  }).every((e) => e);
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = logisticTask?.map((n) => n.logisticTaskId);
      setSelectedTasks(newSelected);
      return;
    }
    setSelectedTasks([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selectedTasks.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedTasks, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedTasks.slice(1));
    } else if (selectedIndex === selectedTasks.length - 1) {
      newSelected = newSelected.concat(selectedTasks.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedTasks.slice(0, selectedIndex),
        selectedTasks.slice(selectedIndex + 1),
      );
    }
    // onSelectItem(selectedIndex, item);
    setSelectedTasks(newSelected);
  };
  const getBaseInfo = (start) => {
    const params = {
      "companyId.equals": companyId,
    };
    if (startDateCreatedAt) {
      // eslint-disable-next-line no-unused-expressions
      params["createDate.greaterThanOrEqual"] = startDateCreatedAt.toISOString();
    }
    if (name) {
      // eslint-disable-next-line no-unused-expressions
      params["name.notEquals"] = name;
    }
    if (finishDateCreatedAt) {
      // eslint-disable-next-line no-unused-expressions
      params["createDate.lessThanOrEqual"] = finishDateCreatedAt.toISOString();
    }
    if (startDateStartAt) {
      // eslint-disable-next-line no-unused-expressions
      params["startTime.greaterThanOrEqual"] = startDateStartAt.toISOString();
    }
    if (finishDateStartAt) {
      // eslint-disable-next-line no-unused-expressions
      params["finishTime.lessThanOrEqual"] = finishDateStartAt.toISOString();
    }
    if (startDateCreatedAt
        || name
        || finishDateCreatedAt
        || startDateStartAt
        || finishDateStartAt
        || logisticTask === null || start) {
      getLogisticTasks({
        params
      }).then((e) => {
        if (e?.data) {
          setLogisticTask(e.data);
        }
      });
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleDelete = async (id) => deleteLogisticTasks({ id });

  const handleStart = (id) => {
    startLogisticTasks({ id }).finally(() => {
      getBaseInfo();
    });
  };

  const handleStop = (id) => {
    stopLogisticTasks({ id }).finally(() => {
      getBaseInfo();
    });
  };

  const handlePause = (id) => {
    pauseLogisticTasks({ id }).finally(() => {
      getBaseInfo();
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selectedTasks.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  // eslint-disable-next-line no-unsafe-optional-chaining
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - logisticTask?.length) : 0;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElement, setAnchorElement] = React.useState(null);
  const open = anchorEl;
  const handleClickFunc = (event, index) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(index);
    setAnchorElement(event.currentTarget);
  };
  const handleClose = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);
    setAnchorElement(null);
  };

  useEffect(() => {
    getBaseInfo();
  }, [
    getLogisticTasks,
    startDateCreatedAt,
    finishDateCreatedAt,
    startDateStartAt,
    finishDateStartAt,
    companyId,
    name
  ]);

  const handleEdit = (row) => {
    const e = row?.type || row?.solution?.type;
    switch (e) {
      case "VRP":
      case "VRPSolution":
        navigate(`/logistics/delivery/${row.logisticTaskId}/main`);
        break;
      case "SHORT_PATH":
      case "shortPathSolution":
        navigate(`/logistics/short-path/${row.logisticTaskId}/main`);
        break;
      case "BALANCE_CAPACITY":
      case "balanceCapacity":
        navigate(`/logistics/power-balancing/${row.logisticTaskId}/main`);
        break;
      default:
        navigate(`/logistics/edit/${row.logisticTaskId}`);
    }
  };

  return (
    <>
      <EnhancedTableToolbar
        setStartDateCreatedAt={setStartDateCreatedAt}
        setFinishDateCreatedAt={setFinishDateCreatedAt}
        setStartDateStartAt={setStartDateStartAt}
        setFinishDateStartAt={setFinishDateStartAt}
        startDateCreatedAt={startDateCreatedAt}
        finishDateCreatedAt={finishDateCreatedAt}
        startDateStartAt={startDateStartAt}
        finishDateStartAt={finishDateStartAt}
        name={name}
        setName={setName}
      />
      <Paper sx={{ width: '100%', mb: 2 }} elevation={1}>

        {!logisticTask ? (
          <Box
            sx={{
              display: 'flex',
              height: "444px",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            {' '}
            <Box>

              <Table
                sx={{ width: "100%", mt: "10px" }}
                aria-labelledby="tableTitle"
                size="small"
              >
                <EnhancedTableHead
                  numSelected={selectedTasks.length}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={logisticTask?.length}
                  headCells={headCells}
                  onRequestSort={() => {}}
                  order={undefined}
                  orderBy={undefined}
                />
                <TableBody>
                  {sortByDateDescending((rowsPerPage > 0
                    ? logisticTask?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : logisticTask
                  ))?.map((row, index) => {
                    const isItemSelected = isSelected(row.logisticTaskId);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.logisticTaskId)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={`TableRow-${row.logisticTaskId}`}
                        selected={isItemSelected}
                        sx={{ cursor: 'pointer' }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        </TableCell>
                        <TableCell align="left">{row.name || "-"}</TableCell>
                        <TableCell align="right">{toRuStatus(row.status)}</TableCell>
                        <TableCell align="right">{toRuType(row)}</TableCell>

                        <TableCell align="right">{row.isRunnable ? "Да" : "Нет"}</TableCell>
                        <TableCell align="right">{ row?.startTime ? isValidDate(new Date(row?.startTime)) ? formatDate(new Date(row.startTime)) : "-" : "-" }</TableCell>
                        <TableCell align="right">{row?.createdDate ? isValidDate(new Date(row?.createdDate)) ? formatDate(new Date(row.createdDate)) : "-" : "-"}</TableCell>
                        <TableCell align="right">
                          <IconButton onClick={(e) => handleClickFunc(e, index)} size="small">
                            <MoreVertIcon fontSize="small" />
                          </IconButton>
                          <StyledMenu
                            id="demo-customized-menu"
                            MenuListProps={{
                              'aria-labelledby': 'demo-customized-button',
                            }}
                            anchorEl={anchorElement}
                            open={open === index}
                            onClose={handleClose}
                            onClick={(event) => {
                              event.stopPropagation();
                            }}
                          >
                            <MenuItem
                              disabled={!row.isRunnable}
                              onClick={(event) => {
                                handleClose(event);
                                handleStart(row.logisticTaskId);
                              }}
                            >
                              <PlayCircleIcon color="primary" />
                              Запустить
                            </MenuItem>
                            <MenuItem
                              disabled={!row.isRunnable}
                              onClick={(event) => {
                                handleClose(row.logisticTaskId);
                                handleClose(event);
                              }}
                            >
                              <DoDisturbIcon color="primary" />
                              Остановить
                            </MenuItem>
                            <MenuItem
                              disabled={!row.isRunnable}
                              onClick={(event) => {
                                handleStop(row.logisticTaskId);
                                handlePause(event);
                              }}
                            >
                              <PauseIcon color="primary" />
                              Пауза
                            </MenuItem>
                            <MenuItem
                              onClick={(event) => {
                                handleClose(event);
                                handleEdit(row);
                              }}
                            >
                              <ModeEditIcon color="primary" />
                              Редактировать
                            </MenuItem>
                            <Divider sx={{ my: 0.5 }} />
                            <MenuItem
                              onClick={(event) => {
                                event.preventDefault();
                                confirm({
                                  title: 'Удалить задачу?',
                                  confirmationText: 'Да',
                                  cancellationText: 'Нет',
                                  description: `Вы уверены, что хотите удалить задачу?`
                                })
                                  .then(() => {
                                    handleDelete(row.logisticTaskId).then(() => {
                                      getBaseInfo(true);
                                    });
                                    handleClose(event);
                                  }).catch(() => {
                                    handleClose(event);
                                  });
                              }}
                              disableRipple
                            >
                              <DeleteIcon color="primary" />
                              Удалить
                            </MenuItem>
                          </StyledMenu>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 33 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

              </Table>
              <Box sx={{
                display: "flex", gap: "20px", marginLeft: "auto", width: "max-content", mt: "20px"
              }}
              >
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => {
                    selectedTasks.forEach((e) => {
                      handleStart(e);
                    });
                  }}
                  disabled={!selectedTasks?.length || !selectedTasksIsRunnable}
                >
                  Запустить
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  onClick={() => {
                    confirm({
                      title: 'Остановить задачи?',
                      confirmationText: 'Да',
                      cancellationText: 'Отмена',
                      description: `Вы действительно хотите остановить задачу(и)?`
                    })
                      .then(() => selectedTasks.forEach((e) => {
                        handleStop(e);
                      }))
                      .catch(() => { });
                  }}
                  disabled={!selectedTasks?.length || !selectedTasksIsRunnable}
                >
                  Остановить
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  onClick={() => {
                    confirm({
                      title: 'Поставить задачу(и) на паузу?',
                      confirmationText: 'Да',
                      cancellationText: 'Отмена',
                      description: `Вы действительно хотите поставить задачу(и) на паузу?`
                    })
                      .then(() => selectedTasks.forEach((e) => {
                        handlePause(e);
                      }))
                      .catch(() => { });
                  }}
                  disabled={!selectedTasks?.length || !selectedTasksIsRunnable}
                >
                  Пауза
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => {
                    confirm({
                      title: 'Удаление задач!',
                      confirmationText: 'Да',
                      cancellationText: 'Отмена',
                      description: `Вы действительно хотите удалить задачи?`
                    })
                      .then(() => {
                        const requests = selectedTasks.map(
                          (taskCurrent) => handleDelete(taskCurrent).then(() => {
                            getBaseInfo(true);
                          })
                        );

                        Promise.all(requests).then(() => {
                          getBaseInfo();
                        });
                      })
                      .catch(() => { });
                  }}
                  disabled={!selectedTasks?.length}
                >
                  Удалить
                </Button>
              </Box>
            </Box>
            <TablePagination
              rowsPerPageOptions={[15, 50, 100]}
              component="div"
              count={logisticTask?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}

      </Paper>
    </>
  );
}

export function FileName({ nameFile = "" }) {
  return (
    <Typography
      className={style.ellipsis}
      variant="p"
      component="h4"
      sx={{ color: 'text.secondary' }}
    >
      Файл:
      {' '}
      {nameFile}
    </Typography>
  );
}

FileName.propTypes = {
  nameFile: PropTypes.string.isRequired,
};

CompanyLogisticsTablePanel.propTypes = {
  company: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ])),
};

CompanyLogisticsTablePanel.defaultProps = {
  company: null,
};

export default CompanyLogisticsTablePanel;
