import SockJS from "sockjs-client";
import Stomp from "webstomp-client";
import config from './config';

let stompClient = null;
const topicsForSubscription = [];

const connect = () => {
  const token = window.localStorage.getItem('token') || window.sessionStorage.getItem('token');
  const url = `${config.socketUrl}?access_token=${token}`;

  const socket = new SockJS(url);
  stompClient = Stomp.over(socket, { protocols: ['v12.stomp'] });

  const headers = {};
  stompClient.connect(headers);
};

const checkConnect = () => new Promise((resolve) => {
  if (stompClient === null) {
    connect();
  }
  const connectInterval = setInterval(() => {
    if (stompClient?.connected) {
      resolve(stompClient);
      clearInterval(connectInterval);
    }
  }, 300);
});

export const subscribe = async (topic, callback) => {
  topicsForSubscription.push(topic);
  const connectedclient = await checkConnect();
  return topicsForSubscription.includes(topic)
    ? connectedclient.subscribe(topic, (data) => {
      callback(JSON.parse(data.body));
    })
    : null;
};

export const unsubscribe = (subscriber, topic) => {
  const index = topicsForSubscription.indexOf(topic);
  if (index !== -1) {
    topicsForSubscription.splice(index, 1);
  }
  if (subscriber !== null) {
    subscriber.unsubscribe();
  }
  if (stompClient !== null) {
    if (stompClient.connected && topicsForSubscription.length === 0) {
      stompClient.disconnect();
      stompClient = null;
    }
  }
};
