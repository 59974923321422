import { Box, Container, Divider } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import Tree from "rc-tree";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SmartToyIcon from '@mui/icons-material/SmartToy';
// import SettingsIcon from '@mui/icons-material/Settings';
import {
  Route, Routes, useLocation, useNavigate
} from "react-router-dom";
import TabsPanels from "../../../shared/ui/TabsPanels/TabsPanels";
import IconTree from "../../../shared/ui/IconTree/IconTree";
import AccountMainPanel from "../../../panels/account/AccountMainPanel/AccountMainPanel";
import AlertsPanel from "../../../panels/account/AlertsPanel/AlertsPanel";
import AppUserSettingsResourceBll
  from "../../../entities/account/appUserSettingsResource/AppUserSettingsResourceBll/AppUserSettingsResourceBll";
import ChangePasswordPanel from "../../../panels/account/ChangePasswordPanel/ChangePasswordPanel";
import AccountResourceBll from "../../../entities/account/accountResource/AccountResourceBll/AccountResourceBll";
import AccountMainBll from "../../../entities/account/AccountMain/AccountMain";
import useAuth from "../../../shared/lib/hooks/useAuth";

const switchKeyRoute = (e) => {
  switch (e) {
    case '/account/info':
      return '0';
    case '/account/alerts':
      return '1';
    // case '/account/settings':
    //   return '2';
    default:
      return '0';
  }
};

function AccountView() {
  const { user: userCurrent } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedKeys, setSelectedKeys] = useState([]);

  const tabsAccount = useMemo(() => ([{
    id: 1,
    name: 'Информация',
    component: (
      <AccountMainBll idUser={userCurrent.appUserId} login={userCurrent.login}>
        <AccountMainPanel />
      </AccountMainBll>
    )
  },
  {
    id: 2,
    name: 'Изменить пароль',
    component: (
      <AccountResourceBll>
        <ChangePasswordPanel />
      </AccountResourceBll>
    )
  }]), []);
  const tabsAlerts = useMemo(() => ([{
    id: 2,
    name: 'Telegram',
    component: (
      <AppUserSettingsResourceBll idUser={userCurrent.appUserId}>
        <AlertsPanel idUser={userCurrent.appUserId} />
      </AppUserSettingsResourceBll>
    )
  }]), []);
  // Пока не нужен
  // const tabsSettings = useMemo(() => ([{
  //   id: 3,
  //   name: 'настройки',
  //   component: (
  //     <div>настройки</div>
  //   )
  // }]), []);

  useEffect(() => {
    setSelectedKeys(switchKeyRoute(location.pathname));
  });

  return (
    <Container
      component="main"
      maxWidth="xl"
    >
      <Box sx={{ display: 'flex', width: '100%', gap: '20px' }}>
        <Box sx={{
          paddingTop: '20px',
          width: 240,
          overflowX: 'auto',
          zIndex: 10,
          height: 'calc(100vh - 64px)',
        }}
        >
          <Tree
            showLine
            onSelect={(e, k) => { navigate(k.node.id); }}
            selectedKeys={selectedKeys}
            treeData={[{
              id: 'info', key: '0', title: "Моя учётная запись", nodeType: "ACCOUNT", icon: <IconTree icon={AccountCircleIcon} />
            },
            {
              id: 'alerts', key: '1', title: "Оповещения", nodeType: "Alerts", icon: <IconTree icon={SmartToyIcon} />
            },
            /*
            {
              id: 'settings',
              key: '2',
              title: "Настройки",
              nodeType: "SETTINGS",
              icon: <IconTree icon={SettingsIcon} />
            }
            */
            ]}
          />
        </Box>
        <Divider orientation="vertical" flexItem />

        <Routes>
          <Route>
            <Route path="info" element={<TabsPanels tabs={tabsAccount} />} />
            <Route path="alerts" element={<TabsPanels tabs={tabsAlerts} />} />
            {/* <Route path="settings" element={<TabsPanels tabs={tabsSettings} />} /> */}
          </Route>
        </Routes>

      </Box>
    </Container>
  );
}

export default AccountView;
