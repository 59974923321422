import React, {
  Children, cloneElement, useCallback, useEffect, useState
} from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import {
  useLazyGetPlatoonGroupsIdQuery,
  usePatchPlatoonGroupsMutation,
  usePostPlatoonGroupsMutation
} from "../platoonGroupsResource/redux/platoonGroupsResource.api";
import {
  useLazyGetPlatoonRequestsResourceQuery,
  usePatchPlatoonRequestsResourceMutation,
  usePostPlatoonRequestsResourceMutation
} from "../platoonRequestsResource/redux/platoonRequestsResource.api";
// eslint-disable-next-line boundaries/element-types
// eslint-disable-next-line boundaries/element-types
import {
  useGetVehiclesRouteResourceQuery,
  useLazyGetVehiclesRouteResourceQuery
} from "../../vehicles/VehiclesRoute/vehiclesRoutes/redux/vehiclesRoutes.api";
import {
  useLazyGetPlatoonGroupVehiclesResourceQuery,
  usePatchPlatoonGroupVehiclesResourceMutation
} from "../platoonGroupVehiclesResource/redux/platoonGroupVehiclesResource.api";
import {
  useLazyGetPlatoonGroupActivitiesResourceQuery
} from "../platoonGroupActivitiesResource/redux/platoonGroupActivitiesResource.api";
import {
  useDeletePlatoonGroupParamsResourceMutation,
  useLazyGetPlatoonGroupParamsResourceQuery,
  usePatchPlatoonGroupParamsResourceMutation,
  usePostPlatoonGroupParamsResourceMutation, usePostPlatoonGroupParamsResourceRouteMutation
} from "../platoonGroupParamsResource/redux/platoonGroupParamsResource.api";
// eslint-disable-next-line boundaries/element-types
import { useGetVehiclesResourceQuery } from "../../vehicles/vehicle/redux/vehicle.api";
// eslint-disable-next-line boundaries/element-types
import {
  useGetVehicleRoutePointsResourceQuery
} from "../../vehicles/VehiclesRoute/vehiclesRoutePointsResource/redux/vehiclesRoutePointsResource.api";
import { VEHICLE_GROUP_UPDATE_INTERVAL_MS } from "../constants";

// Компонента Бизнес уровня которая является связующим звеном между фронтом и бизнесом
// Компонента возвращает Children с новыми пропсами из бизнес уровня
function PlatoonBll({
  children, companyId, pollMode
}) {
  const { platoonGroupId } = useParams();
  const [getPlatoonGroupsId, { data: dataPlatoonGroups }] = useLazyGetPlatoonGroupsIdQuery();

  const [
    getPlatoonRequestsResource,
    { data: dataPlatoonRequestsResource }
  ] = useLazyGetPlatoonRequestsResourceQuery();

  const [
    getPlatoonGroupParamsResource,
    { data: dataPlatoonGroupParamsResource }
  ] = useLazyGetPlatoonGroupParamsResourceQuery();

  const [getPlatoonGroupActivitiesResource,
    { data: dataPlatoonGroupActivitiesResource }
  ] = useLazyGetPlatoonGroupActivitiesResourceQuery();

  const [getPlatoonGroupVehiclesResource,
    { data: dataPlatoonGroupVehiclesResource }
  ] = useLazyGetPlatoonGroupVehiclesResourceQuery();

  const [getVehiclesRoutes, vehiclesRoutes] = useLazyGetVehiclesRouteResourceQuery();

  const { data: dataVehicleRoutePointsResource } = useGetVehicleRoutePointsResourceQuery({
    params: {
      "companyId.equals": companyId
    }
  });

  const { data: dataVehiclesResource } = useGetVehiclesResourceQuery({
    params: {
      "companyId.equals": companyId
    },
  });

  const { data: dataVehiclesRouteResource } = useGetVehiclesRouteResourceQuery({
    params: {
      "companyId.equals": companyId,
      page: 0,
      size: 100000,
      sort: 'name,asc'
    },
  });

  const getResources = () => {
    getPlatoonGroupsId({
      id: platoonGroupId
    });
    getPlatoonRequestsResource({
      params: {
        "groupId.equals": platoonGroupId
      }
    });
    getPlatoonGroupParamsResource({
      params: {
        "groupId.equals": platoonGroupId
      }
    });
    getPlatoonGroupActivitiesResource({
      params: {
        "groupId.equals": platoonGroupId,
        sort: "modifiedDate,desc"
      }
    });
    getPlatoonGroupVehiclesResource({
      params: {
        "groupId.equals": platoonGroupId,
        "status.notEquals": 'CANCEL',
      }
    });
  };

  useEffect(() => {
    let platoonInterval;
    if (platoonGroupId) {
      getResources();
      if (pollMode) {
        platoonInterval = setInterval(() => {
          getResources();
        }, VEHICLE_GROUP_UPDATE_INTERVAL_MS);
      }
    }
    return () => {
      clearInterval(platoonInterval);
    };
  }, []);

  const [patchPlatoonGroupsResource] = usePatchPlatoonGroupsMutation();
  const [postPlatoonGroupsResource] = usePostPlatoonGroupsMutation();

  const [patchPlatoonRequestsResource] = usePatchPlatoonRequestsResourceMutation();
  const [patchPlatoonGroupVehiclesResource] = usePatchPlatoonGroupVehiclesResourceMutation();

  const [patchPlatoonGroupParamsResource] = usePatchPlatoonGroupParamsResourceMutation();
  const [postPlatoonGroupParamsResourceRoute] = usePostPlatoonGroupParamsResourceRouteMutation();

  const [deletePlatoonGroupParamsResource] = useDeletePlatoonGroupParamsResourceMutation();

  const [postPlatoonGroupParamsResource] = usePostPlatoonGroupParamsResourceMutation();

  const [postPlatoonRequestsResource] = usePostPlatoonRequestsResourceMutation();

  const postPlatoonRequestsResourceParams = useCallback(
    ({ body }) => postPlatoonRequestsResource({
      body
    }).then((e) => {
      if (!e?.error) {
        getPlatoonRequestsResource({
          params: {
            "groupId.equals": platoonGroupId
          }
        });
      }
    }),
    [postPlatoonRequestsResource]
  );

  const deletePlatoonGroupParamsResourceParams = useCallback(
    ({ id }) => deletePlatoonGroupParamsResource({
      id,
    }),
    []
  );

  const postPlatoonGroupsResourceParams = useCallback(
    ({ body }) => postPlatoonGroupsResource({
      body
    }),
    []
  );

  const postPlatoonGroupParamsResourceParamsRoute = useCallback(
    ({ id, body }) => postPlatoonGroupParamsResourceRoute({
      id,
      body
    }),
    []
  );

  const patchPlatoonGroupParamsResourceParams = useCallback(
    ({ id, body }) => patchPlatoonGroupParamsResource({
      id,
      body
    }),
    []
  );

  const postPlatoonGroupParamsResourceParams = useCallback(
    ({ body }) => postPlatoonGroupParamsResource({
      body
    }),
    []
  );

  const patchPlatoonGroupsResourceParams = useCallback(
    ({ id, body }) => patchPlatoonGroupsResource({
      id,
      body
    }),
    []
  );

  const patchPlatoonGroupVehiclesResourceParams = useCallback(
    ({ id, body }) => patchPlatoonGroupVehiclesResource({
      id,
      body
    }),
    []
  );

  const patchPlatoonRequestsResourceParams = useCallback(
    ({ id, body }) => patchPlatoonRequestsResource({
      id,
      body
    }),
    []
  );

  const [data, setData] = useState();

  useEffect(() => {
    (async () => {
      const newData = {};
      newData.dataPlatoonGroups = dataPlatoonGroups;
      newData.dataPlatoonRequestsResource = dataPlatoonRequestsResource;
      newData.dataPlatoonGroupVehiclesResource = dataPlatoonGroupVehiclesResource;
      newData.dataPlatoonGroupActivitiesResource = dataPlatoonGroupActivitiesResource;
      newData.dataPlatoonGroupParamsResource = dataPlatoonGroupParamsResource;
      newData.dataVehiclesResource = dataVehiclesResource;
      newData.dataVehiclesRouteResource = dataVehiclesRouteResource;
      newData.dataVehicleRoutePointsResource = dataVehicleRoutePointsResource;
      setData(newData);
    })();
  }, [
    dataPlatoonGroups,
    dataPlatoonRequestsResource,
    dataPlatoonGroupVehiclesResource,
    dataPlatoonGroupActivitiesResource,
    dataPlatoonGroupParamsResource,
    dataVehiclesResource,
    dataVehiclesRouteResource,
    dataVehicleRoutePointsResource
  ]);

  const props = {
    data,
    platoonGroupId,
    patchPlatoonGroupsResourceParams,
    postPlatoonGroupsResourceParams,
    patchPlatoonRequestsResourceParams,
    patchPlatoonGroupVehiclesResourceParams,
    patchPlatoonGroupParamsResourceParams,
    deletePlatoonGroupParamsResourceParams,
    postPlatoonGroupParamsResourceParams,
    postPlatoonRequestsResourceParams,
    getVehiclesRoutes,
    vehiclesRoutes,
    companyId,
    postPlatoonGroupParamsResourceParamsRoute
  };

  return (
    <>
      {Children.toArray(children).map((child) => cloneElement(child, props))}
    </>
  );
}

PlatoonBll.propTypes = {
  children: PropTypes.element.isRequired,
  companyId: PropTypes.number,
  pollMode: PropTypes.bool,
};

PlatoonBll.defaultProps = {
  companyId: NaN,
  pollMode: false,
};

export default PlatoonBll;
