import React, { useMemo, useState } from 'react';
import AuthContext from '../../shared/lib/auth/AuthContext';
// eslint-disable-next-line boundaries/element-types
import {
  tariffPlansResourceApi
} from '../../entities/finances/tariffPlansResource/redux/tariffPlansResource.api';
// eslint-disable-next-line boundaries/element-types
import companyAccountsApi from '../../entities/companies/companyAccounts/redux/companyAccounts.api';

// eslint-disable-next-line react/prop-types
function AuthProvider({ children }) {
  const [user, setUser] = useState(undefined);

  const { data: tariffPlans } = tariffPlansResourceApi.useGetTariffPlansQuery();

  const { data: companyAccount } = companyAccountsApi.useGetCompanyAccountsQuery({
    params: user?.company?.companyId ? { 'companyId.equals': user.company.companyId } : undefined
  }, { skip: !user?.company?.companyId });

  const { currentTariffPlan, nextTariffPlan } = useMemo(() => {
    const companyAcc = companyAccount?.[0];
    const currentTarifPlanId = companyAcc?.currentPlan?.tarifPlanId;
    const nextTariffPlanId = companyAcc?.nextPlan?.tarifPlanId;
    const currentTariff = tariffPlans?.data?.find(
      (tariff) => tariff.tarifPlanId === currentTarifPlanId
    ) || null;
    const nextTariff = tariffPlans?.data?.find(
      (tariff) => tariff.tarifPlanId === nextTariffPlanId
    ) || null;
    return { currentTariffPlan: currentTariff, nextTariffPlan: nextTariff };
  }, [companyAccount, tariffPlans]);

  const signin = (newUser, callback) => {
    setUser(newUser);
    callback();
  };

  const signout = (callback) => {
    setUser(null);
    callback();
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    user: user ? { ...user, currentTariffPlan, nextTariffPlan } : undefined,
    companyAccount: companyAccount?.[0],
    signin,
    signout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider;
