import { fromLonLat, toLonLat } from 'ol/proj';

export function parsePolygon(area, start = '((') {
  try {
    return area
      .slice(area.indexOf(start) + start.length, -start.length)
      .split(',')
      .map((coords) => {
        const coordinates = fromLonLat(coords.trim().split(' ').map((coord) => Number(coord)));
        if (Number.isNaN(coordinates[0]) || Number.isNaN(coordinates[1])) {
          throw TypeError('Invalid coordinates');
        }
        return coordinates;
      });
  } catch (error) {
    return null;
  }
}

export function extractCoordinates(lineString) {
  // Проверяем, существует ли строка
  if (!lineString || typeof lineString !== 'string') {
    return [];
  }

  // Используем регулярное выражение для извлечения координат из строки
  const coordinatesMatch = lineString.match(/(\d+\.\d+)\s(\d+\.\d+)/g);

  // Проверяем, были ли найдены координаты
  if (!coordinatesMatch) {
    return [];
  }

  // Преобразуем найденные координаты в формат массива
  return coordinatesMatch.map((coord) => coord.split(' ').map(Number));
}

export function parseLine(track) {
  const line = 'LINESTRING(';
  try {
    return track
      .slice(track.indexOf(line) + line.length, -1)
      .split(',')
      .map((coords) => fromLonLat(coords.trim().split(' ').map((coord) => Number(coord)).reverse()));
  } catch (error) {
    return [];
  }
}

export function coordsToString(coordinates) {
  return `POLYGON((${coordinates.map((coor) => toLonLat(coor).join(' ')).join(',')}))`;
}

export function calcCenterPolygon(polygon) {
  if (polygon.length === 0) {
    return [];
  }
  let sumX = 0;
  let sumY = 0;
  const coordsPolygon = polygon.slice(0, -1);
  coordsPolygon.forEach((coords) => {
    sumY += coords[0];
    sumX += coords[1];
  });
  const centerX = sumX / coordsPolygon.length;
  const centerY = sumY / coordsPolygon.length;
  return [centerY, centerX];
}
