import { createSlice } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import reducerPath from "../../../../app/reducerPath";

export const tabsPanelStateEnum = {
  company: "company",
  division: "division",
  divisions: "divisions",
  vehicle: "vehicle",
  user: "user",
  users: "users",
  passengers: "passengers",
  cargo: "cargo",
};

export const tabsRoutes = {
  [tabsPanelStateEnum.company]: {
    main: 'main',
    finance: 'finance',
    geomap: 'geomap',
    routes: 'routes',
    geozones: 'geozones',
    alerts: 'alerts',
    platoons: 'platoons',
    logistics: 'logistics',
    access: 'access',
    reports: 'reports',
  },
  [tabsPanelStateEnum.vehicle]: {
    main: 'main',
    history: 'history',
    fuelefficiency: 'fuelefficiency',
    equipment: 'equipment',
    state: 'state',
    platoon: 'platoon',
  },
  [tabsPanelStateEnum.division]: {
    main: 'main',
    geomap: 'geomap',
    access: 'access',
  },
  [tabsPanelStateEnum.divisions]: {
    main: 'main',
    access: 'access'
  },
  [tabsPanelStateEnum.user]: {
    main: 'main',
  },
  [tabsPanelStateEnum.users]: {
    main: 'main',
  },
  [tabsPanelStateEnum.passengers]: {
    main: 'main',
  },
  [tabsPanelStateEnum.cargo]: {
    main: 'main',
  },
};

// Инициализация стейта в сторе
const tabsPanelStateInitialState = {
  [tabsPanelStateEnum.company]: tabsRoutes.company.main,
  [tabsPanelStateEnum.division]: tabsRoutes.division.main,
  [tabsPanelStateEnum.divisions]: tabsRoutes.divisions.main,
  [tabsPanelStateEnum.vehicle]: tabsRoutes.vehicle.main,
  [tabsPanelStateEnum.user]: tabsRoutes.user.main,
  [tabsPanelStateEnum.users]: tabsRoutes.users.main,
  [tabsPanelStateEnum.passengers]: tabsRoutes.passengers.main,
  [tabsPanelStateEnum.cargo]: tabsRoutes.cargo.main,
};
// Типизация стора
export const tabsPanelsStateType = {
  [tabsPanelStateEnum.company]: PropTypes.string,
  [tabsPanelStateEnum.division]: PropTypes.string,
  [tabsPanelStateEnum.divisions]: PropTypes.string,
  [tabsPanelStateEnum.vehicle]: PropTypes.string,
  [tabsPanelStateEnum.user]: PropTypes.string,
  [tabsPanelStateEnum.users]: PropTypes.string,
  [tabsPanelStateEnum.passengers]: PropTypes.string,
  [tabsPanelStateEnum.cargo]: PropTypes.string,
};
// Создание слайса тут пишем инициализированный стейт и редюсеры
export const companyTabsPanelStateSlice = createSlice({
  name: `${reducerPath.tabsPanelState}/counter`,
  initialState: tabsPanelStateInitialState,
  reducers: {
    setValue(state, value) {
      return value.payload;
    },
  }
});

export const companyTabsPanelStateReducer = companyTabsPanelStateSlice.reducer;
