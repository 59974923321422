/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import { fromLonLat } from "ol/proj";
import { colors } from "@mui/material";
import reducerPath from "../../../../app/reducerPath";
import { logisticTasksApi } from "./logisticTasks.api";
import colorsOfRoutes from "../colorsOfRoutes";
import isValidCoordinates from "../../../../shared/utils/isValidCoordinates";
import isValidDate from "../../../../shared/utils/isValidDate";

export const ROUTE_NAME_TPLS = [
  { value: '$task - $vehicle - $date', label: '<Название задачи> - <Номер ТС> - <Дата>' },
  { value: '$task - $vehicle_name - $date', label: '<Название задачи> - <Название ТС> - <Дата>' },
  { value: '$date - $task - $vehicle', label: '<Дата> - <Название задачи> - <Номер ТС>' },
  { value: '$date - $task - $vehicle_name', label: '<Дата> - <Название задачи> - <Название ТС>' },
  { value: '$task - $vehicle', label: '<Название задачи> - <Номер ТС>' },
  { value: '$task - $vehicle_name', label: '<Название задачи> - <Название ТС>' },
  { value: '$vehicle - $date', label: '<Номер ТС> - <Дата>' },
  { value: '$vehicle_name - $date', label: '<Название ТС> - <Дата>' },
  { value: '$date - $vehicle', label: '<Дата> - <Номер ТС>' },
  { value: '$date - $vehicle_name', label: '<Дата> - <Название ТС>' },
];

const parseLonLat = (lon, lat) => {
  if (!isValidCoordinates(lon, lat)) {
    return null;
  }
  return fromLonLat([lon, lat]);
};

const parseTime = (date) => {
  if (!isValidDate(date)) {
    return '';
  }
  return date.toLocaleTimeString('ru', { hour: '2-digit', minute: '2-digit' });
};

// Формирует точки по заказу
const createPointsFromOrder = (order, route, color) => {
  const loadingPoint = {
    time: order.loadingTime ? new Date(order.loadingTime) : null,
    isLoading: true,
    isUnloading: false,
    orderNumber: order.id,
    label: 'Погрузка',
    coordinates: parseLonLat(order.loadingLon, order.loadingLat),
    color,
    r: route
  };
  loadingPoint.tooltipText = `${loadingPoint.orderNumber}
  ${loadingPoint.label}
  ${parseTime(loadingPoint.time)}`;
  const unloadingPoint = {
    time: order.deliveryTime ? new Date(order.deliveryTime) : null,
    isLoading: false,
    isUnloading: true,
    label: 'Выгрузка',
    orderNumber: order.id,
    coordinates: parseLonLat(order.deliveryLon, order.deliveryLat),
    color,
    r: route
  };
  unloadingPoint.tooltipText = `${unloadingPoint.orderNumber}
  ${unloadingPoint.label}
  ${parseTime(unloadingPoint.time)}`;
  return { loadingPoint, unloadingPoint };
};

// Преобразует источник и решение связные структуры
const saturateSourceWithSolution = (source, solution) => {
  const newSourse = JSON.parse(JSON.stringify(source));
  let routePointsObject = {};
  const lines = [];

  // К каждому ТС из решения находим ТС в источнике, добавляем данные из решения
  solution?.routes?.forEach((r, i) => {
    let routePoints = []; // Все точки для одного ТС (маршрута)
    const color = colorsOfRoutes[i % colorsOfRoutes.length]; // цвет маршрута

    const veh = newSourse?.vehicleDetailsList?.find((vehDetail) => (
      vehDetail.registrationNumber === r.vehicleDetails.registrationNumber
    ));
    veh.route = r;
    veh.color = color;

    // Если есть координаты точки СТАРТА для ТС, добавляем точку старта
    const startCoords = parseLonLat(r.vehicleDetails.startLon, r.vehicleDetails.startLat);
    if (startCoords) {
      const startPoint = {
        id: crypto.randomUUID(),
        coordinates: startCoords,
        label: 'Старт',
        color,
        r
      };
      startPoint.tooltipText = `${startPoint.label}`;
      routePoints.push(startPoint);
    }

    // Для всех заказов в решении находим заказ в источнике, добавляем данные из решения
    r.orders?.forEach((solOrder) => {
      const order = newSourse?.orderSourceList?.find((ord) => (
        ord.id === solOrder.orderSource.id
      ));
      if (order) {
        order.vehicleNumber = r.vehicleDetails.registrationNumber;
        order.statuses = solOrder.statuses;
        order.color = color;
        order.loadingTime = solOrder.loadingTime;
        order.deliveryTime = solOrder.deliveryTime;
        order.isSolved = true;

        const points = createPointsFromOrder(order, r, color); // формируем точки
        routePoints.push(points.loadingPoint);
        routePoints.push(points.unloadingPoint);
      }
    });

    // Сортируем точки по времени из решения
    routePoints = routePoints.sort((a, b) => a.time - b.time);

    // Если есть координаты точки ФИНИША для ТС, добавляем точку финиша
    const finishCoords = parseLonLat(r.vehicleDetails.finishLon, r.vehicleDetails.finishLat);
    if (finishCoords) {
      const finishPoint = {
        id: crypto.randomUUID(),
        coordinates: finishCoords,
        label: 'Финиш',
        color,
        r
      };
      finishPoint.tooltipText = `${finishPoint.label}`;
      routePoints.push(finishPoint);
    }

    // Для соединения точек формируем из них линии по маршруту
    lines.push({
      r,
      color,
      id: crypto.randomUUID(),
      coordinates: routePoints.reduce((prev, routePoint) => {
        if (routePoint.coordinates) {
          prev.push(routePoint.coordinates);
        }
        return prev;
      }, [])
    });

    // Преобразуем массив точек в объкт для более удобного редактирования
    routePoints.forEach((routePoint, index) => {
      const id = crypto.randomUUID();
      routePointsObject = {
        ...routePointsObject,
        [`${id}`]: {
          id,
          ...routePoint,
          index: index + 1,
          time: routePoint.time?.toISOString(),
        }
      };
    });
  });

  // Для нераспределенных точек проставляем статусы
  solution?.unallocatedOrders?.forEach((unallocatedOrder) => {
    const order = newSourse?.orderSourceList?.find((orderSource) => (
      orderSource.id === unallocatedOrder.orderSource.id
    ));
    if (order) {
      order.statuses = unallocatedOrder.statuses;
    }
  });

  // Из оставшихся заказов, которых нет в решении, формируем точки
  newSourse?.orderSourceList.forEach((orderSource) => {
    if (!orderSource.isSolved) {
      const points = createPointsFromOrder(orderSource, null, colors.grey[600]);
      const loadingId = crypto.randomUUID();
      const unloadingId = crypto.randomUUID();
      routePointsObject = {
        ...routePointsObject,
        [`${loadingId}`]: {
          id: loadingId,
          ...points.loadingPoint,
          index: 'A',
        },
        [`${unloadingId}`]: {
          id: unloadingId,
          ...points.unloadingPoint,
          index: 'B',
        }
      };
    }
  });
  newSourse.points = routePointsObject;
  newSourse.lines = lines;
  return newSourse;
};

// Преобразовать данные для таблицы ТС
const transformVehicles = (vehList = []) => {
  const newVehList = vehList?.map((veh) => {
    const statuses = veh?.route?.orders
      ?.reduce((prev, order) => prev.concat(order.statuses), []) || [];
    return {
      ...veh,
      id: veh.registrationNumber,
      color: veh?.color,
      startWork: veh.firstShiftWorkTimeFrom,
      finishWork: veh.firstShiftWorkTimeTo,
      countOrders: veh.route?.orders?.length || 0,
      weight: veh.liftingCapacity,
      weightMax: veh.maxWeightPercents,
      km: veh.route?.routeLength || 0,
      statuses,
      func: "",
    };
  });
  return newVehList;
};

// Функция для извлечения времени в формате "часы:минуты:секунды"
function extractTime2(dateTimeString) {
  const dateTime = new Date(dateTimeString);
  const hours = String(dateTime.getHours()).padStart(2, '0');
  const minutes = String(dateTime.getMinutes()).padStart(2, '0');
  const seconds = String(dateTime.getSeconds()).padStart(2, '0');
  return `${hours}:${minutes}:${seconds}`;
}

function getTimesById(orders, id) {
  return orders.reduce((acc, order) => {
    if (order.orderSource && order.orderSource.id === id) {
      // Извлекаем время доставки
      if (order.deliveryTime) {
        acc.deliveryTime = extractTime2(order.deliveryTime);
      }
      // Извлекаем время загрузки
      if (order.loadingTime) {
        acc.loadingTime = extractTime2(order.loadingTime);
      }
    }
    return acc;
  }, { deliveryTime: "", loadingTime: "" });
}

function extractTime(isoString) {
  const date = new Date(isoString);
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${hours}:${minutes}:${seconds}`;
}

// Преобразовать данные для таблицы Заказов
// eslint-disable-next-line default-param-last
const transformOrders = (ordList = [], routes) => ordList?.map((order) => {
  const { deliveryTime, loadingTime } = !order.color ? getTimesById(routes, order?.id) : { deliveryTime: "", loadingTime: "" };

  return ({
    ...order,
    id: order?.id,
    additionalNum: order?.additionalNum,
    orderNumber: order?.id,
    color: order.color,
    loading: order.color ? order?.loadingTime ? extractTime(order?.loadingTime) : "" : loadingTime,
    unloading: order.color ? order?.deliveryTime ? extractTime(order?.deliveryTime) : "" : deliveryTime,
    addressLoading: order?.loadingAddress,
    addressUnloading: order?.deliveryAddress,
    weight: order?.weight,
    places: order?.cargoCount,
    statuses: order?.statuses || [],
    timeWindowFrom: order?.timeWindowFrom,
    timeWindowTo: order?.timeWindowTo
  });
});

export const transformData = (data) => {
  const sourceWithSolution = saturateSourceWithSolution(data?.source, data?.solution);
  return {
    ...data,
    sourceWithSolution: {
      vehicleDetailsList: transformVehicles(sourceWithSolution?.vehicleDetailsList),
      orderSourceList: transformOrders(
        sourceWithSolution?.orderSourceList,
        data?.solution?.unallocatedOrders
      ),
      points: sourceWithSolution.points,
      lines: sourceWithSolution.lines,
    }
  };
};

// Название полей которые в data после функции from dto
// Название полей которые будут в сторе в секции reducerPath.adaptersResource/api`
export const LogisticTasksEnum = {
  data: "data",
  reorderedData: "reorderedData",
  selectedOrders: "selectedOrders",
  initialData: "initialData",
  selectedVehicles: "selectedVehicles",
  routes: "routes",
  rePlanDate: "rePlanDate",
  routeNameTemplate: "routeNameTemplate",
};

// Инициализация стейта в сторе
const logisticTasksResourceInitialState = {
  [LogisticTasksEnum.data]: {},
  [LogisticTasksEnum.reorderedData]: {},
  [LogisticTasksEnum.initialData]: {},
  [LogisticTasksEnum.selectedOrders]: [],
  [LogisticTasksEnum.selectedVehicles]: [],
  [LogisticTasksEnum.routes]: [],
  [LogisticTasksEnum.rePlanDate]: null,
  [LogisticTasksEnum.routeNameTemplate]: ROUTE_NAME_TPLS[0].value,
};
// Типизация стора
export const logisticTasksResourceType = {
  [LogisticTasksEnum.data]: PropTypes.any,
};
// Создание слайса тут пишем инициализированный стейт и редюсеры
export const logisticTasksSlice = createSlice({
  name: `${reducerPath.logisticTasksResource}/counter`,
  initialState: logisticTasksResourceInitialState,
  reducers: {
    setData(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    setSelectedOrders(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    setSelectedVehicles(state, action) {
      state.selectedVehicles = action.payload;
    },
    setVehicleAssigned(state, action) {
      const newData = transformData(action.payload);
      state.data = newData;
    },
    setRePlanDate(state, action) {
      state.rePlanDate = action.payload;
    },
    setRouteNameTemplate(state, action) {
      state.routeNameTemplate = action.payload;
    },
    setTaskName(state, action) {
      state.data.name = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(logisticTasksApi.endpoints.getLogisticTaskId.matchFulfilled, (state, action) => {
        if (action.payload?.type === 'VRP') {
          const newData = transformData(action.payload);
          state.initialData = newData;
          state.data = newData;
        }
      });
  }
});

export const logisticTasksResourceActions = logisticTasksSlice.actions;
export const logisticTasksResourceReducer = logisticTasksSlice.reducer;
