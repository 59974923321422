import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  TextField,
  Typography,
  Link as LinkMUI,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { getAccount, getAppUsers, getToken } from '../../../shared/api/api';
import useAuth from '../../../shared/lib/hooks/useAuth';
import VkAuth from "./auth";
// eslint-disable-next-line import/no-unresolved
import { AUTH_FIELDS, authActions, clearStorage } from "../../../entities/auth/tokenResource/redux/tokenResource";
import { useAppDispatch } from "../../../app/store";
import DriverAppIcon from '../../../shared/ui/DriverAppIcon/DriverAppIcon';
import SchoolBusAppIcon from '../../../shared/ui/SchoolBusAppIcon/SchoolBusAppIcon';
import RuStoreBanner from '../../../assets/icons/RuStoreBanner';

// const DRIVER_APP_HREF = 'https://vm.vistar.ru/distr/vistarm_driver.apk';
// const SCHOOL_BUS_APP_HREF = 'https://vm.vistar.ru/distr/school_bus.apk';

const DRIVER_APP_HREF = 'https://www.rustore.ru/catalog/app/su.vistar.vistarm';
const SCHOOL_BUS_APP_HREF = 'https://www.rustore.ru/catalog/app/su.vistar.vistarm.passenger';

function LoadingAuth() {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        gap: "30px",
      }}
    >
      <Box sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: "15px",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",

      }}
      >
        <Typography variant="h6" component="div" sx={{ flexGrow: 1, width: "100%" }}>
          ВИСТАР-М
        </Typography>
        <CircularProgress />
      </Box>
    </Box>
  );
}

function LoginView() {
  const navigate = useNavigate();
  const location = useLocation();
  const { signin } = useAuth();
  const dispatch = useAppDispatch();

  const pathname = location.state?.from?.pathname || '/';
  const searchParams = location.state?.from?.search || '';
  const from = `${pathname}${searchParams}`;

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [token, updateToken] = useState(null);
  const [error, setError] = useState(null);
  const [load, setLoad] = useState(true);

  const login = (e) => {
    e.preventDefault();
    const body = {
      username,
      password,
      rememberMe,
    };
    getToken(body)
      .then((res) => {
        clearStorage();
        // eslint-disable-next-line no-undef
        if (body.rememberMe) window.localStorage.setItem('token', res);
        // eslint-disable-next-line no-undef
        else window.sessionStorage.setItem('token', res);
        dispatch(authActions.login({ [AUTH_FIELDS.TOKEN_ACCESS]: res }));
        updateToken(res);
      })
    // eslint-disable-next-line no-shadow
      .catch((error) => {
        if (error.status === 401) {
          setError("Неправильный логин или пароль");
        } else {
          setError("Произошла ошибка");
        }
        throw error;
      });
  };

  useEffect(() => {
    getAccount()
      .then((userCurrent) => {
        if (userCurrent.login) {
          getAppUsers({ userId: userCurrent.id }).then((appUser) => {
            const currentUser = appUser[0];
            const userInfo = {
              appUserId: currentUser.appUserId,
              company: currentUser.company,
              ...userCurrent,
            };
            let redirectUrl = from;
            if (redirectUrl === '/') {
              const visitVistarM = window.localStorage.getItem('visitVistarM');
              if (visitVistarM) {
                redirectUrl = '/companies';
                window.localStorage.setItem('visitVistarM', true);
              } else {
                redirectUrl = "/quick-start";
                window.localStorage.setItem('visitVistarM', true);
              }
            }
            signin(userInfo, () => {
              navigate(redirectUrl, { replace: true });
            });
          })
            .finally(() => setLoad(false));
        }
        if (!token) setError("Неправильный логин или пароль");
      })
      .catch(() => {
        signin(null, () => {});
        setLoad(false);
      });
  }, [token, signin, from, navigate]);

  useEffect(() => {
    setError(null);
  }, [username, password]);

  return !load
    ? (
      <Container
        component="main"
        maxWidth="xs"
      >
        <>
          <Box
            sx={{
              marginTop: 6,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginBottom: 1
            }}
          >
            <Avatar sx={{ m: 1 }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Авторизация
            </Typography>
            <Box component="form" onSubmit={login} noValidate sx={{ mt: 1 }}>
              <TextField
                onChange={(e) => setUsername(e.target.value)}
                value={username}
                id="username"
                label="Логин"
                type="text"
                fullWidth
                margin="normal"
                required
                name="username"
                autoComplete="username"
                autoFocus
              />
              <TextField
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                id="password"
                type="password"
                label="Пароль"
                fullWidth
                margin="normal"
                required
                name="password"
                autoComplete="current-password"
              />
              {error ? (
                <Typography variant="body1" component="p" sx={{ color: "red", margin: "10px" }}>
                  {error}
                </Typography>
              )
                : null}
              <Box sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
              >
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={rememberMe}
                      color="primary"
                      onChange={(e) => setRememberMe(e.target.checked)}
                    />
                      )}
                  label="Запомнить меня"
                />
                <Link to="/forgotPassword" variant="body2">
                  Восстановить пароль
                </Link>
              </Box>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={username.length === 0 || password.length === 0}
              >
                Войти
              </Button>
              {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}
            </Box>
          </Box>
          <VkAuth />
          <Typography sx={{
            mt: 3, mb: 1, textAlign: 'center', justifyContent: 'space-around'
          }}
          >
            Установить приложение для Android:
          </Typography>
          <List sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
            <ListItem
              component={LinkMUI}
              href={DRIVER_APP_HREF}
              underline="hover"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                display: 'flex', flexDirection: 'column', textAlign: 'center', width: '50%', minWidth: '164px'
              }}
            >
              <DriverAppIcon sx={{ width: '36px', height: '36px', borderRadius: '4px' }} />
              <ListItemText primary={(
                <Typography sx={{ lineHeight: '1rem', my: 1 }}>
                  ВИСТАР-М.
                  {' '}
                  <br />
                  Водитель
                </Typography>
                )}
              />
              <RuStoreBanner />
            </ListItem>
            <ListItem
              component={LinkMUI}
              href={SCHOOL_BUS_APP_HREF}
              underline="hover"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                display: 'flex', flexDirection: 'column', textAlign: 'center', width: '50%', minWidth: '164px'
              }}
            >
              <SchoolBusAppIcon sx={{ width: '36px', height: '36px', borderRadius: '4px' }} />
              <ListItemText
                primary={(
                  <Typography sx={{ lineHeight: '1rem', my: 1 }}>
                    ВИСТАР-М.
                    {' '}
                    <br />
                    Школьный автобус
                  </Typography>
                )}
              />
              <RuStoreBanner />
            </ListItem>
          </List>
        </>
      </Container>
    )
    : <LoadingAuth />;
}

export default LoginView;
