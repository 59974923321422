import { Container } from '@mui/material';
import React, { useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import TabsPanels from '../../../shared/ui/TabsPanels/TabsPanels';
import ReportingPanel from "../../../panels/reporting/ReportingPanel/ReportingPanel";
import VehicleReportingBll from "../../../entities/vehicles/vehicle/VehicleReportingBll/VehicleReportingBll";

// Отображает страницу "Отчетность"
function ReportingView() {
  const tabsFinance = useMemo(() => ([
    {
      name: 'Данные ТС по пробегу',
      component: (
        <Routes>
          <Route
            path="/"
            element={(
              <VehicleReportingBll>
                <ReportingPanel />
              </VehicleReportingBll>

            )}
          />
        </Routes>
      )
    }
  ]), []);

  return (
    <Container
      component="main"
      maxWidth="xl"
    >
      <TabsPanels tabs={tabsFinance} />
    </Container>
  );
}

export default ReportingView;
