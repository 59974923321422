// eslint-disable-next-line
import ERROR_CODES from "./ERROR_CODES";
// eslint-disable-next-line
import t from '../localisation/t';

function getErrorMessage(errorCode, error = {}) {
  return t(errorCode || error.errorCode)
    || error.detail
    || error.title
    || t(ERROR_CODES.UNKNOWN_ERROR);
}

export default getErrorMessage;
