import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Connect } from "@vkontakte/superappkit";
import { Box, Tooltip } from '@mui/material';
import style from './Header.module.css';
// eslint-disable-next-line boundaries/element-types
import { clearStorage } from "../../../entities/auth/tokenResource/redux/tokenResource";

const appVersion = process.env.REACT_APP_VERSION || '';

function Header(props) {
  const { handleDrawer } = props;

  const logout = () => {
    Connect.logout().finally(() => {
      clearStorage();
      // eslint-disable-next-line no-undef
      window.location.href = '/';
    });
  };

  return (
    <AppBar sx={{ zIndex: 1500 }} position="static" color="primary" className={style.header}>
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={handleDrawer}
        >
          <MenuIcon />
        </IconButton>
        <Box className={style.headerMain}>
          <Box className={style.headerText}>
            <Typography variant="h6" component="div">
              ВИСТАР-М
            </Typography>
            <Typography variant="caption" component="div">
              {appVersion}
            </Typography>
          </Box>
        </Box>

        <div className={style.buttons}>
          <Tooltip title="Быстрый старт">
            <IconButton
              color="inherit"
              href="/quick-start"
            >
              <HelpOutlineIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Button onClick={logout} color="inherit">Выход</Button>
        </div>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  handleDrawer: PropTypes.func.isRequired,
};

export default Header;
