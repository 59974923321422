import { createSlice } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import reducerPath from "../../../../app/reducerPath";

// Название полей которые в data после функции from dto
// Название полей которые будут в сторе в секции reducerPath.adaptersResource/api`
export const ZoomControllerEnum = {
  zoom: "zoom",
};
// Инициализация стейта в сторе
const zoomControllerInitialState = {
  [ZoomControllerEnum.zoom]: 14,
};
// Типизация стора
export const zoomControllerType = {
  [ZoomControllerEnum.zoom]: PropTypes.number,
};
// Создание слайса тут пишем инициализированный стейт и редюсеры
export const zoomControllerSlice = createSlice({
  name: `${reducerPath.zoomController}/counter`,
  initialState: zoomControllerInitialState,
  reducers: {
    changeMode(state, action) {
      return { ...action.payload };
    },
    getData(state, action) {
      return { ...action.payload };
    }
  }
});

export const zoomControllerActions = zoomControllerSlice.actions;

export const zoomControllerReducer = zoomControllerSlice.reducer;
