import { toast } from 'react-hot-toast';
import ERROR_CODES from "./ERROR_CODES";
import t from '../localisation/t';
import getErrorCode from './getErrorCode';
import getErrorMessage from './getErrorMessage';

// Обработка ошибок запросов к серверу
function handleError(error, { isRTK = false, isToastUI = true } = {}) {
  let newError = error;
  // Коррекция структуры ошибки в случае, если ошибка из RTK
  if (isRTK) {
    if (error?.data) {
      newError.response = error?.data;
    } else if (error?.error?.status === "FETCH_ERROR") {
      newError.request = error?.meta?.request;
    }
  }
  let errorCode;
  let errorMessage;
  if (error.response) {
    // Запрос сделан и сервер отдал отшибку
    newError = error?.response?.data || error?.response;
    errorCode = getErrorCode(newError?.status, newError);
    errorMessage = getErrorMessage(errorCode, newError);
  } else if (error.request) {
    // Нет ответа от сервера
    newError = error.request;
    errorCode = ERROR_CODES.NO_RESPONSE;
    errorMessage = t(ERROR_CODES.NO_RESPONSE);
  } else {
    // Что-то произошло при настройке запроса
    errorCode = ERROR_CODES.NO_REQUEST;
    errorMessage = t(ERROR_CODES.NO_REQUEST);
  }
  if (error?.response?.status !== 401 && isToastUI) {
    toast.error(errorMessage);
  }
  newError = {
    ...error.toJSON?.(),
    ...newError,
    errorCode,
    errorMessage
  };
  console.error(newError);
  return newError;
}

export default handleError;
