import React, {
  useCallback, useEffect, useMemo, useRef, useState
} from 'react';
import {
  Alert, AlertTitle, Box, Button, Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from "react-router-dom";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import style from './CompanyLogisticsPanel.module.css';
import useShowError from "../../../shared/lib/hooks/useShowError";
import useAuth from "../../../shared/lib/hooks/useAuth";
import {
  usePostLogisticsResourceVrpMutation
} from "../../../entities/logistic/logisticTasks/redux/logisticTasks.api";
import Loading from '../../../shared/ui/Loading/Loading';
import FileName from '../../../shared/ui/FileName/FileName';
import validateFile from '../../../shared/utils/validateFile';

// Фунция для получения текста ошибки по коду ошиибки запроса
const getErrorTextFromCodeRequest = (code, text) => {
  switch (code) {
    case 400:
      return text;
    case 403:
      return "У вас нет доступа";
    case 404:
      return "Под этим аккаунтом невозможно создать задачу, так как у вас нет привязанной компании.";
    default:
      return "Неизвестная ошибка, повторите позже.";
  }
};
// Текст ошибки при обработке файл/ загрузке и тд
const defaultError = "Ошибка при обработке файла, попробуйте еще раз";
// Текст ошибка при обработке размера файла
const sizeError = "Максимально допустимый размер файла 5 MB";
// Отображает панель "Логистики" для ТС
function CompanyDeliveryOrdersPanel({ company }) {
  const [postLogisticsResourceTrigger, {
    isError: isErrorPostLogisticsResource,
    isLoading: isLoadingPostLogisticsResource,
    isSuccess: isSuccessPostLogisticsResource,
    data: dataPostLogisticsResource,
    error: errorPostLogisticsResource
  }] = usePostLogisticsResourceVrpMutation();
  const [exelFile, setExelFile] = useState(null);
  const [exel1File, setExel1File] = useState(null);
  const fileExelFileRef = useRef(null);
  const fileExel1FileRef = useRef(null);
  const showError = useShowError();
  const auth = useAuth();
  const appUserId = useMemo(() => auth?.user?.appUserId, [auth]);
  const companyId = useMemo(() => company?.companyId, [company]);
  const navigate = useNavigate();

  // Обработка файла после нажатия на кнопку "выбрать exel  формата первый файл "
  const handleExelFileChange = useCallback((event) => {
    const selectedFile = event.target.files[0];

    if (!selectedFile?.name?.endsWith('.xlsx')) {
      showError("Допустимый формат только .xlsx, выберите другой файл");
      return null;
    }
    if (!validateFile(selectedFile)) {
      showError(sizeError);
      return null;
    }
    if (selectedFile) {
      setExelFile(selectedFile);
    } else {
      showError(defaultError);
      return null;
    }
    return selectedFile;
  }, [showError, setExelFile]);

  // Обработка файла после нажатия на кнопку "выбрать exel файл 2-го"
  const handleExel1FileChange = useCallback((event) => {
    const selectedFile = event.target.files[0];

    if (!selectedFile?.name?.endsWith('.xlsx')) {
      showError("Допустимый формат только .xlsx, выберите другой файл");
      return null;
    }
    if (!validateFile(selectedFile)) {
      showError(sizeError);
      return null;
    }
    if (selectedFile) {
      setExel1File(selectedFile);
    } else {
      showError(defaultError);
      return null;
    }
    return selectedFile;
  }, [showError, setExel1File]);

  // Обработка нажатия на кнопку "выбрать exel формата 1 файл"
  const handleCsvFileClick = useCallback(() => {
    if (fileExelFileRef.current.click) {
      fileExelFileRef.current.click();
    } else {
      showError(defaultError);
    }
  }, [fileExelFileRef]);

  // Обработка нажатия на кнопку "выбрать zip формата"
  const handleExel1FileClick = useCallback(() => {
    if (fileExel1FileRef.current.click) {
      fileExel1FileRef.current.click();
    } else {
      showError(defaultError);
    }
  }, [fileExel1FileRef]);

  // Обработка нажатия на кнопку "Создать задачу"
  const handleFormButtonClick = useCallback(() => {
    if (exel1File && exelFile) {
      const formData = new FormData();
      formData.append('vehiclesFile', exel1File, `${exel1File.name}.xlsx`);
      formData.append('ordersFile', exelFile, `${exelFile.name}.xlsx`);
      postLogisticsResourceTrigger({
        body: formData,
        id: appUserId
      });
    } else {
      showError(defaultError);
    }
  }, [postLogisticsResourceTrigger, exelFile, appUserId, companyId, exel1File]);

  useEffect(() => {
    if (dataPostLogisticsResource) {
      const logisticTaskId = dataPostLogisticsResource?.data?.logisticTaskId;
      if (logisticTaskId) {
        navigate(`/logistics/delivery/${logisticTaskId}/main`);
      }
    }
  }, [dataPostLogisticsResource]);

  return (
    <Box className={style.companyLogisticsPanel}>
      <Accordion sx={{ p: 1, width: "100%" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ fontSize: "18px" }}>
            Доставка заказов
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {isLoadingPostLogisticsResource ? <Loading /> : (
            <Box className={style.formContainer}>
              <Box className={style.formItem}>
                <Typography variant="body1" component="div" sx={{ display: 'flex' }}>
                  Файл со списком заказов (формат XLSX)
                </Typography>
                <Box className={style.formItemFile}>
                  <input
                    ref={fileExelFileRef}
                    accept=".xlsx"
                    style={{ display: 'none' }}
                    id="raised-button-file2"
                    multiple
                    type="file"
                    onChange={handleExelFileChange}
                  />
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="raised-button-file2">
                    <Tooltip title="" placement="right" arrow>
                      <Button
                        onClick={handleCsvFileClick}
                        variant={exelFile ? "contained" : "outlined"}
                        component="button"
                        sx={{ minWidth: '210px' }}
                      >
                        {exelFile ? "Изменить файл" : "Выбрать файл"}
                      </Button>
                    </Tooltip>
                  </label>
                  {exelFile?.name && <FileName nameFile={exelFile?.name} /> }
                </Box>
              </Box>
              <Box className={style.formItem}>
                <Typography variant="body1" component="div" sx={{ display: 'flex' }}>
                  Файл со списком ТС (формат XLSX)
                </Typography>
                <Box className={style.formItemFile}>
                  <input
                    ref={fileExel1FileRef}
                    accept=".xlsx"
                    style={{ display: 'none' }}
                    id="raised-button-file3"
                    multiple
                    type="file"
                    onChange={handleExel1FileChange}
                  />
                  <label htmlFor="raised-button-file3">
                    <Tooltip title="" placement="right" arrow>
                      <Button
                        onClick={handleExel1FileClick}
                        variant={exel1File ? "contained" : "outlined"}
                        component="button"
                        sx={{ minWidth: '210px' }}
                      >
                        {exel1File ? "Изменить файл" : "Выбрать файл"}
                      </Button>
                    </Tooltip>
                  </label>
                  {exel1File?.name && <FileName nameFile={exel1File?.name} /> }
                </Box>
              </Box>
              {isErrorPostLogisticsResource && (
              <Alert sx={{ width: "100%" }} severity="error">
                <AlertTitle>Ошибка</AlertTitle>
                {getErrorTextFromCodeRequest(
                  errorPostLogisticsResource?.data?.status,
                  errorPostLogisticsResource?.data?.title
                )}
              </Alert>
              ) }
              {isSuccessPostLogisticsResource ? dataPostLogisticsResource.data?.logisticTaskId ? (
                <Alert sx={{ width: "100%" }} severity="success">
                  <AlertTitle>Успешно</AlertTitle>
                  Данные успешно сохранены
                </Alert>
              ) : (
                <Alert sx={{ width: "100%" }} severity="error">
                  <AlertTitle>Ошибка</AlertTitle>
                  Данные успешно сохранены
                </Alert>
              ) : null }
              <Box sx={{ marginLeft: "auto" }}>
                <Button
                  onClick={handleFormButtonClick}
                  disabled={!exelFile || !exel1File}
                  variant="contained"
                  component="button"
                >
                  Создать задачу
                </Button>
              </Box>
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

CompanyDeliveryOrdersPanel.propTypes = {
  company: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ])),
};

CompanyDeliveryOrdersPanel.defaultProps = {
  company: null,
};

export default CompanyDeliveryOrdersPanel;
