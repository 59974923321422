const ERROR_CODES = {
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
  NOT_FOUND: 'NOT_FOUND',
  FORBIDDEN: 'FORBIDDEN',
  GEOZONE_NOT_DELETE: 'GEOZONE_NOT_DELETE',
  NO_RESPONSE: 'NO_RESPONSE',
  NO_REQUEST: 'NO_REQUEST',
};

export default ERROR_CODES;
