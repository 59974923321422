import {
  Box, IconButton, TextField
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import ApartmentIcon from "@mui/icons-material/Apartment";
import CompanyTreeWidget from '../../../widgets/companies/CompanyTreeWidget';
import {
  useGetDivisionsResourceQuery
} from "../../../entities/divisions/divisionsResource/redux/divisionsResource.api";
import { useLazyGetVehiclesResourceQuery } from "../../../entities/vehicles/vehicle/redux/vehicle.api";
import useDebounce from "../../../shared/hooks/useDebounce";
import IconButtonBadge from "../../../shared/ui/IconButtonBadge/IconButtonBadge";
import useAuth from "../../../shared/lib/hooks/useAuth";

const debounceDelay = 500;
// функция которая приводит строку к поиску
function checkSubstring(str1, str2) {
  return str1.toLowerCase()
    .replaceAll(' ', '')
    .includes(str2.toLowerCase().replaceAll(' ', ''));
}

// функция которая закрывает все дочерние узлы дерева
const closeTree = (tree) => tree?.map((node) => {
  const newObj = { ...node };
  if (node.children) {
    newObj.children = closeTree(node.children);
  }
  newObj.style = { display: "none" };
  return newObj;
});

// divisions - Все дивизионы
// id - id дивизиона который в дереве
// search - ТС которое нашли по поиску
function extractDivisionIdsFromTree(divisions, id, search) {
  let result = false;
  function traverse(vehicleDivisionId, treeDivisionId, allDivisions) {
    const vehicleDivision = allDivisions.find((e) => e.divisionId === vehicleDivisionId);

    if (vehicleDivision.divisionId === treeDivisionId) {
      result = true;
    } else if (vehicleDivision.parent && typeof vehicleDivision.parent === 'object') {
      traverse(vehicleDivision.parent.divisionId, id, divisions);
    }
  }
  search.map((e) => {
    traverse(e.division.divisionId, id, divisions);
    return e;
  });

  return result;
}

const helper = (tree, search, divisions) => tree.map((e) => {
  switch (e.nodeType) {
    case "DIVISIONS": {
      if (search.filter((currentEl) => currentEl.division.divisionId === e.data.id).length) {
        e.style = { display: "block" };
      } else if (search.filter((vehicle) => vehicle?.company?.companyId
        === e?.data.company?.companyId).length) {
        e.style = { display: "block" };
      } else {
        e.style = { display: "none" };
        e.children = closeTree(e.children);
      }
      if (e.children) {
        const newChildren = helper(e.children, search, divisions);
        e.children = newChildren;
      }
      return e;
    }
    case "DIVISION": {
      if (search.filter((currentEl) => currentEl.division.divisionId === e.data.id).length) {
        e.style = { display: "block" };
      } else if (extractDivisionIdsFromTree(divisions.data, e.data.divisionId, search)) {
        e.style = { display: "block" };
      } else {
        e.style = { display: "none" };
        e.children = closeTree(e.children);
      }
      if (e.children) {
        const newChildren = helper(e.children, search, divisions);
        e.children = newChildren;
      }
      return e;
    }
    case "VEHICLE": {
      if (search.filter((currentEl) => currentEl.vehicleId === e.data.vehicleId).length) {
        e.style = { display: "block" };
      } else {
        e.style = { display: "none" };
        e.children = closeTree(e.children);
      }
      if (e.children) {
        const newChildren = helper(e.children, search, divisions);
        e.children = newChildren;
      }
      return e;
    }
    default: {
      e.style = { display: "none" };
      e.children = closeTree(e.children);
      return e;
    }
  }
});

function CompanyTreePanel(props) {
  const {
    companies, treeData, setTreeData, setSelectedData,
    treeSelectedKeys, setTreeSelectedKeys, isFormChanged, setIsFormChanged,
    setIsCreateCompany, toogleSelectedNode, expandedKeys, setExpandedKeys,
    isAdmin, treeWidth
  } = props;
  const [search, setSearch] = useState('');
  const [newTreeDataFromApi, setNewTreeDataFromApi] = useState([]);
  const [isloadedTree, setIsloadedTree] = useState(false);
  const [
    fetchVehiclesResource, { isLoading, data: dataVehiclesResource }
  ] = useLazyGetVehiclesResourceQuery();
  const { user } = useAuth();

  const { data: dataDivisionsResource } = useGetDivisionsResourceQuery(
    {
      params: user.authorities.includes("ROLE_ADMIN") ? {} : companies ? {
        "companyId.equals": companies[0]?.companyId
      } : {}
    },
  );

  const backToDefaultTree = (tree) => tree.map((node) => {
    const newObj = { ...node };
    if (node.children) {
      newObj.children = backToDefaultTree(node.children);
    }
    newObj.style = { display: "block" };
    return newObj;
  });

  const newTreeData = useMemo(() => {
    let isFound = false;
    const arr = isloadedTree
      ? backToDefaultTree(treeData).map((e) => {
        if (checkSubstring(e.title, search)) {
          if ((search.length >= 3)) {
            isFound = true;
          }
          e.style = { display: "block" };
        } else if (search.length >= 3) {
          e.style = { display: "none" };
          e.children = closeTree(e.children);
        }
        return e;
      })
      : treeData;

    if ((search.length >= 3) && !isFound) {
      const newArr = treeData.map((e) => {
        if (dataVehiclesResource && dataVehiclesResource.data && dataVehiclesResource.data
          .filter((currentEl) => currentEl.company.companyId === e.data.companyId).length) {
          e.style = { display: "block" };
        } else {
          e.style = { display: "none" };
          e.children = closeTree(e.children);
        }
        if (e.children && dataVehiclesResource?.data) {
          const newChildren = helper(
            e.children,
            dataVehiclesResource.data,
            dataDivisionsResource
          );
          e.children = newChildren;
        }
        return e;
      });
      setNewTreeDataFromApi(newArr);
    } else {
      setNewTreeDataFromApi(arr);
    }
    return arr;
  }, [search, treeData, dataDivisionsResource, dataVehiclesResource, isloadedTree]);

  const debouncedSearch = useDebounce((value) => {
    if (value.length >= 3) {
      fetchVehiclesResource({
        params: {
          "name.contains": value,
        },
      });
    }
  }, debounceDelay);

  // Вызываем debouncedSearch с новым значением search каждый раз, когда пользователь что-то вводит
  useEffect(() => {
    debouncedSearch(search);
  }, [search, debouncedSearch]);

  useEffect(() => {
    if (treeSelectedKeys?.length) {
      setIsloadedTree(true);
    }
  }, [treeSelectedKeys]);

  const [hover, setHover] = useState(false);

  return (
    <Box
      sx={{
        paddingTop: '20px',
        paddingBottom: '20px',
        paddingRight: '10px',
        width: `${treeWidth - 23}px`,
        overflowX: 'hidden',
        overflowY: hover ? 'auto' : 'hidden',
        zIndex: 10,
        height: '100vh',
        position: 'sticky',
        top: '0px',
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Box sx={{
        display: "flex", alignItems: "center", gap: "10px", marginBottom: "20px", width: '260px', paddingRight: '10px'
      }}
      >
        <TextField
          value={search}
          size="small"
          onChange={(e) => { setSearch(e.target.value); }}
          id="searchThree"
          label="Поиск"
          variant="outlined"
          InputProps={{
            endAdornment: search.length >= 3 && (
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setSearch('')}
              >
                <CloseIcon />
              </IconButton>
            )
          }}
        />
        {
          isAdmin
            ? (
              <IconButtonBadge
                title="Создать компанию"
                onClick={() => { setIsCreateCompany(true); }}
              >
                <ApartmentIcon />
              </IconButtonBadge>
            )
            : null
        }
      </Box>
      {isLoading
        ? "Загрузка"
        : (
          <CompanyTreeWidget
            treeSelectedKeys={treeSelectedKeys}
            setTreeSelectedKeys={setTreeSelectedKeys}
            setSelectedData={setSelectedData}
            treeData={(search.length >= 3) ? newTreeDataFromApi : newTreeData}
            setTreeData={setTreeData}
            data={companies}
            isFormChanged={isFormChanged}
            setIsFormChanged={setIsFormChanged}
            toogleSelectedNode={toogleSelectedNode}
            expandedKeys={expandedKeys}
            setExpandedKeys={setExpandedKeys}
          />
        )}
    </Box>
  );
}

CompanyTreePanel.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.any,
    PropTypes.oneOf([null]),
  ])),
  treeData: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  setTreeData: PropTypes.func.isRequired,
  setSelectedData: PropTypes.func.isRequired,
  treeSelectedKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  setTreeSelectedKeys: PropTypes.func.isRequired,
  isFormChanged: PropTypes.bool.isRequired,
  setIsFormChanged: PropTypes.func.isRequired,
  setIsCreateCompany: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  toogleSelectedNode: PropTypes.func.isRequired,
  expandedKeys: PropTypes.arrayOf(PropTypes.string),
  setExpandedKeys: PropTypes.func.isRequired,
  treeWidth: PropTypes.number,
};

CompanyTreePanel.defaultProps = {
  companies: null,
  expandedKeys: null,
  treeWidth: 290,
};

export default CompanyTreePanel;
