import {
  Box, Typography,
} from '@mui/material';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import style from './PlatoonGroup.module.css';
import CarIcon from '../../../shared/ui/CarIcon/CarIcon';
import convertSecondsToTimeString from '../../../shared/utils/convertSecondsToTimeString';
import convertFormatDate from "../../../shared/utils/convertFormatDate";

// Показ Группы ТС
function PlatoonGroup({ platoonGroup, buttons }) {
  const [hover, setHover] = useState(false);
  return (
    <Box
      className={style.platoonGroup}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {platoonGroup?.name
        ? (
          <Box className={style.headerGroup}>
            <Typography variant="h6" component="div" sx={{ color: 'text.secondary' }}>
              {platoonGroup.name}
              {' '}
              {`(с ${convertFormatDate(platoonGroup.startDateFrom).toFormat.slice(0, -3)}, каждые ${platoonGroup.intervalDuration})`}
            </Typography>
            <Box className={hover ? style.buttonsVisible : style.buttonsHidden}>
              {/* Взаимодействие с группой */}
              {buttons}
            </Box>
          </Box>
        )
        : null}
      <Box className={style.platoonGroupCard}>
        <Box className={style.groupVehiclesContainer}>
          <Box
            className={platoonGroup?.noFinishVehicles?.length > 6
              ? style.groupVehiclesLong
              : style.groupVehicles}
          >
            <Box className={style.groupPointsContainer}>
              <Box className={style.lineContainer}>
                <Box className={style.line} />
              </Box>
              <Box className={style.points}>
                {platoonGroup?.noFinishVehicles.map((point) => {
                  const colorPointText = Number(point.time) > 0 ? '#e33371' : '#0B8108';
                  return (
                    <Box key={point.id} className={style.point}>
                      <Box className={style.labelTop}>
                        {point.time
                          ? (
                            <Typography variant="subtitle2" component="div" align="center" sx={{ color: colorPointText }}>
                              {convertSecondsToTimeString(point.time)}
                            </Typography>
                          )
                          : null}
                      </Box>
                      {point.simplePoint
                        ? <Brightness1Icon color="primary" />
                        : <CarIcon className={style.iconPoint} />}
                      <Box className={style.labelBottom}>
                        <Typography variant="subtitle2" component="div" sx={{ color: '#e33371' }}>
                          {point.num}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
            <Box className={style.finishVehicles}>
              {platoonGroup?.finishVehicles?.map((point) => (
                <Box key={point.id} className={style.point}>
                  <CarIcon className={style.iconPoint} />
                  <Box className={style.labelBottom}>
                    <Typography variant="subtitle2" component="div" sx={{ color: '#e33371' }}>
                      {point.num}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

PlatoonGroup.propTypes = {
  platoonGroup: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ])).isRequired,
  buttons: PropTypes.element.isRequired,
};

export default PlatoonGroup;
